import { LOCALES } from "../locales";

const a = {
    [LOCALES.RUSSIAN]: {
        'lang': 'ru',
        'title': 'Рыбалка на озере Слобода',
        'name': 'Сергей',
        'slogan': 'Озеро "Слобода"',

        'menu_link_0' :'О нас',
        'menu_link_1' :'Правила',
        'menu_link_2' :'Трофеи',
        'menu_link_3' :'FAQ',
        'menu_link_4' :'Фото',
        'menu_link_5' :'Отзывы',
        'menu_link_6' :'Что нового?',
        'menu_link_7' :'Контакты',
        'menu_link_8' :'Главная',
        'menu_link_9' :'Донаты',

        'donate_title_2': 'Помощь в развитии портала',
        'donate_subtitle_2': '100% пожертвованных средств пойдут на развитие портала',

        'button_text_1': 'Фото секторов',
        'button_text_2': 'Смотреть все',
        'button_text_3': 'Помочь',

        'header-text': 'Озеро Слобода расположено в райском уголке, где Вы сможете насладиться неописуемой природной красотой, прекрасно отдохнуть, а для любителей спортивной рыбалки места лучше нет.',
        'footer-social-title': 'Мы в соц. сетях:',

        'about-title': 'О нас',
        'rules-title': 'Правила',
        'trophies-title': 'Трофеи',
        'faq-title': 'FAQ',
        'faq-description': 'Мы постарались собрать часто задаваемые вопросы, также Вы всегда сможете связатся с орендатором озера и уточнить если не нашли ответа',
        'photo-title': 'Фото',
        'reviews-title': 'Отзывы',
        'news-title': 'Новости озера',
        'contacts-title': 'Как нас найти?',
        'contacts-description': 'GPS координаты: 49.9190804, 30.8320398 Едем по трассе Киев-Кагарлык, на 71 километре видим указатель с. Слобода и поворачиваем на лево, в селе на т-образном перекрестке держимся левее, и через 500м с левой стороны, будет виден водоем и парковка для автомобилей.',

        'about-subtitle-0': "ОСОБЕННОСТИ ЛОВЛИ",
        'about-subtitle-1': "ОСОБЕННОСТИ ВОДОЕМА",
        'about-subtitle-2': "КАК НАС НАЙТИ?",
        'about-item-0-text-1': "Спортивная рыбалка",
        'about-item-0-text-2': "Водоем постоянно зарыбляется, рыба очень сильная, даже карпы на 2кг доставят вам максимум удовольствия. На водоеме присутствуют крупные карпы от 1 кг до 25кг. Белые амуры от 1 кг до 17кг. Толстолоб до 30кг. Карась до 2кг",
        'about-item-0-text-3': "Рыбалка разрешена только с использованием карпового мата, подсака, мешков для хранения рыбы.",
        'about-item-1-text-1': "Расположение",
        'about-item-1-text-2': "Украина, Киевская область, село Слобода",
        'about-item-1-text-3': "Площадь водоема",
        'about-item-1-text-4': "Макс. глубина",
        'about-item-1-text-5': "Рыба",
        'about-item-1-text-6': "карп, карась, амур, толстолоб, судак, сом, окунь, плотва, раки",
        'about-item-1-text-7': "На водоеме 20 вымосток, паркова для автомобилей, туалет, душ, маленький магазин. Администратор добродушный человек, с которым приятно пообщаться, всегда подскажет как обстоят дела на водоеме. Водоем имеет илистое дно с ровной поверхностью.",
        'about-item-2-text-1': "GPS координаты",
        'about-item-2-text-2': "Едем по трассе Киев-Кагарлык, на 71 километре видим указатель с. Слобода и поворачиваем на лево, в селе на т-образном перекрестке держимся левее, и через 500м с левой стороны, будет виден водоем и парковка для автомобилей.",
        'about-item-2-text-3': "Смотреть карту",
        'about-item-2-text-4': "Карта",

        'faq-item-0-text-1': "Можно ли рыбачить ночью?",
        'faq-item-0-text-2': "Да",
        'faq-item-1-text-1': "Можно ли ловить с лодки?",
        'faq-item-1-text-2': "Рыбалка разрешена только с берега",
        'faq-item-2-text-1': "Как забронировать место?",
        'faq-item-2-text-2': "По телефону +38(066) 386-25-73 Сергей",
        'faq-item-3-text-1': "Кто может находится с рыбаком без оплаты?",
        'faq-item-3-text-2': "С рыбаком на территории озера может прибывать без оплаты члены семьи, друзья при условии что с вымостки рыбалка производится не более чем на 4 удилища.",
        'faq-item-4-text-1': "Обязательно ли бронировать место для рыбалки?",
        'faq-item-4-text-2': "Обязательно. Номер для бронирования +38(066) 386-25-73 Сергей",

        'rules-subtitle-0': 'Правила рыбалки на водоеме Слобода ЧП `Бирюк`',
        'rules-subtitle-1': 'ЗАПРЕЩАЕТСЯ',
        'rules-subtitle-2': 'Рыбалка на водоеме разрешена только с предварительного согласия администрации',
        'rules-item-0-text-0': 'Каждый посетитель обязан соблюдать действующие ПРАВИЛА, чистоту на водоеме, порядок в секторе лова, бережно относится к имуществу водоема и прокатному оборудованию. В случае повреждения имущества водоема или прокатного оборудования, посетитель обязан возместить стоимость нанесенного ущерба.',
        'rules-item-0-text-1': 'Рыбалка на водоеме осуществляется только с оборудованных вымосток по принципу “ПОЙМАЛ-ОТПУСТИ”!!! Рыбалка с берега категорически запрещена!!! Из пойманной рыбы разрешается забрать 3кг рыбы (кроме карпа и белого амура).',
        'rules-item-0-text-2': 'С одной вымостки разрешается ловить рыбу не более чем на 4 удилища оснащенное только одним крючком каждое.',
        'rules-item-0-text-3': 'Выважевание рыбы осуществляется только подхватом с мягкой сеткой. Использование других видов подхвата СТРОГО ЗАПРЕЩЕНО!!! Все действия с рыбой на вымостке и берегу должны происходить на КАРПОВОМ МАТЕ. Для взвешивания рыбы на вывоз приглашается представитель администрации.',
        'rules-item-0-text-4': 'Хранить рыбу для вывоза и взвешивания можно только в мягких садках или КАРПОВЫХ МЕШКАХ.',
        'rules-item-0-text-5': 'Разрешается прикармливать точки лова любыми средствами кормления без нарушения экологии водоема. На территории сектора, в охранной зоне водоема, разрешается установка палаток, зонтов, в отведенных местах-мангала.',
        'rules-item-0-text-6': 'После окончания рыбалки рыболовное место должно быть убрано, мешки с мусором аккуратно сложены в урны, прокатное оборудование сдано администрации и получен залоговый документ. САМОВЫВОЗ МУСОРА ПРИВЕТСТВУЕТСЯ!!!',
        'rules-item-0-text-7': 'Администрация водоема осуществляет контроль за выполнение условий установленных правил.',
        'rules-item-1-text-0': 'Использование любых браконьерских снастей (макушатники, смоктухи, драчи, дорожки, донки и т.п.), а так же металлических садков.',
        'rules-item-1-text-1': 'Монтажи, которые могут покалечить рыбу (спутники, макушатники, пружины и т.п.)',
        'rules-item-1-text-2': 'Шуметь, кричать, устраивать веселье, включать громко музыку, стрелять и т.п.',
        'rules-item-1-text-3': 'Находиться на водоеме в состоянии алкогольного (наркотического) опьянения.',
        'rules-item-1-text-4': 'Заходить в сектор других посетителей без их согласия.',
        'rules-item-1-text-5': 'Использовать металлические садки.',
        'rules-item-1-text-6': 'Купаться в зоне лова рыбы и плавать на плавсредствах.',
        'rules-item-1-text-7': 'Разводить костры в неотведенных для этого местах.',
        'rules-item-1-text-8': 'Бросать окурки и другой мусор в водоем на землю.',
        'rules-item-1-text-9': 'Заходить за охранную зону водоема, луга и огороды жителей села.',
        'rules-item-2-text-0': 'Отказ от сдачи сектора в надлежащей чистоте влечет за собой штраф (200грн)',
        'rules-item-2-text-1': 'Намеренное нанесение повреждений или смерть рыбы влечет за собой штраф в размере 100грн/кг. Администрация имеет право удалить нарушителя правил водоема без возмещения стоимости неиспользованного времени пребывания на водоеме, а нарушитель вносится в черный список.',
        'rules-item-2-text-2': 'Администрация оставляет за собой право вносить изменения и дополнения в данный раздел',
    }
}

export default a
