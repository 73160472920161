import { LOCALES } from "../locales";

const a = {
    [LOCALES.UKRAINIAN]: {
        'lang': 'ua',
        'title': 'Рибалка на озері Слобода',
        'name': 'Сергій',
        'slogan': 'Озеро "Слобода"',

        'menu_link_0' :'Про нас',
        'menu_link_1' :'Правила',
        'menu_link_2' :'Трофеї',
        'menu_link_3' :'FAQ',
        'menu_link_4' :'Фото',
        'menu_link_5' :'Відгуки',
        'menu_link_6' :'Що нового?',
        'menu_link_7' :'Контакти',
        'menu_link_8' :'Головна',
        'menu_link_9' :'Донати',

        'donate_title_2': 'Допомога в розвитку порталу',
        'donate_subtitle_2': '100% пожертвуваних коштів підуть на розвиток порталу',

        'button_text_1': 'Фото секторів',
        'button_text_2': 'Дивитись всі',
        'button_text_3': 'Допомогти',

        'header-text': 'Озеро Слобода розташоване в райському куточку, де Ви зможете насолодитися неймовірною природною красою, чудово відпочити, а для любителів спортивної риболовлі місця кращого немає.',
        'footer-social-title': 'Ми в соц. мережах:',

        'about-title': 'Про нас',
        'rules-title': 'Правила',
        'trophies-title': 'Трофеї',
        'faq-title': 'FAQ',
        'faq-description': 'Ми постаралися зібрати питання, що часто ставляться, також Ви завжди зможете зв\'язатися з орендарем озера і уточнити якщо не знайшли відповіді',
        'photo-title': 'Фото',
        'reviews-title': 'Відгуки',
        'news-title': 'Новини озера',
        'contacts-title': 'Як нас знайти?',
        'contacts-description': 'GPS координати: 49.9190804, 30.8320398 Їдемо трасою Київ-Кагарлик, на 71 кілометрі бачимо вказівник с. Слобода і повертаємо ліворуч, у селі на т-подібному перехресті тримаємося ліворуч, і через 500м з лівого боку, буде видно озеро та паркування для автомобілів.',

        'about-subtitle-0': "Особливості рибалки",
        'about-subtitle-1': "Особливості водоєму",
        'about-subtitle-2': "Як на знайти?",
        'about-item-0-text-1': "Спортивна рибалка",
        'about-item-0-text-2': "Водойма постійно зарибляється, риба дуже сильна, навіть коропи на 2кг принесуть вам максимум задоволення. На водоймі є великі коропи від 1 кг до 25кг. Білі амури від 1 кг до 20 кг. Товстолоба до 37кг. Карась до 3кг",
        'about-item-0-text-3': "Рибалка дозволена лише з використанням коропового мату, підсаку, мішків для зберігання риби.",
        'about-item-1-text-1': "Розташування",
        'about-item-1-text-2': "Україна, Київська область, село Слобода",
        'about-item-1-text-3': "Площа водоймища",
        'about-item-1-text-4': "Макс. глибина",
        'about-item-1-text-5': "Риба",
        'about-item-1-text-6': "короп, карась, амур, товстолоб, судак, сом, окунь, плітка, раки",
        'about-item-1-text-7': "На водоймі 20 вимосток, паркова для автомобілів, туалет, душ, маленький магазин. Адміністратор добродушна людина, з якою приємно поспілкуватися, завжди підкаже, як справи на водоймі. Водойма має мулисте дно з рівною поверхнею.",
        'about-item-2-text-1': "GPS координати",
        'about-item-2-text-2': "Едем по трассе Киев-Кагарлык, на 71 километре видим указатель с. Слобода и поворачиваем на лево, в селе на т-образном перекрестке держимся левее, и через 500м с левой стороны, будет виден водоем и парковка для автомобилей.",
        'about-item-2-text-3': "Дивитись карту",
        'about-item-2-text-4': "Мапа",

        'faq-item-0-text-1': "Чи можна рибалити вночі?",
        'faq-item-0-text-2': "Так",
        'faq-item-1-text-1': "Чи можна ловити з човна?",
        'faq-item-1-text-2': "Рибалка дозволена лише з берега",
        'faq-item-2-text-1': "Як забронювати місце?",
        'faq-item-2-text-2': "За телефоном +38(066) 386-25-73 Сергій",
        'faq-item-3-text-1': "Хто може перебувати з рибалкою без оплати?",
        'faq-item-3-text-2': "З рибалкою на території озера можуть прибувати без оплати члени сім'ї, друзі за умови, що з вимостки рибалка відбувається не більше ніж на 4 вудилища.",
        'faq-item-4-text-1': "Чи обов'язково бронювати місце для риболовлі?",
        'faq-item-4-text-2': "Обов'язково. Номер для бронювання +38(066) 386-25-73 Сергій",

        'rules-subtitle-0': 'Правила риболовлі на водоймі Слобода ПП `Бірюк`',
        'rules-subtitle-1': 'Забороняється',
        'rules-subtitle-2': 'Рибалка на водоймі дозволена лише за попередньою згодою адміністрації',
        'rules-item-0-text-0': 'Кожен відвідувач зобов\'язаний дотримуватися чинних правил, чистоти на водоймі, порядку в секторі лову, дбайливо ставиться до майна водойми та прокатного обладнання. У разі пошкодження майна водойми або прокатного обладнання відвідувач зобов\'язаний відшкодувати вартість завданих збитків.',
        'rules-item-0-text-1': 'Риболовля на водоймі здійснюється тільки з обладнаних вимосток за принципом “ПІЙМАЛ-ВІДПУСТІ”!!! Риболовля з берега категорично заборонена! З пійманої риби дозволяється забрати 3кг риби (крім коропа та білого амура).',
        'rules-item-0-text-2': 'З однієї вимостки дозволяється ловити рибу не більше ніж на 4 вудилища, оснащене тільки одним гачком кожне.',
        'rules-item-0-text-3': 'Виведення риби здійснюється тільки підхопленням з м\'якою сіткою. Використання інших видів підхоплення СТРОГО ЗАБОРОНЕНО! Усі дії з рибою на вимостці та березі мають відбуватися на КАРПОВОМУ МАТІ. Для зважування риби на вивіз запрошується представник адміністрації.',
        'rules-item-0-text-4': 'Зберігати рибу для вивезення та зважування можна тільки в м\'яких садках або КАРПОВИХ МІШКАХ.',
        'rules-item-0-text-5': 'Дозволяється підгодовувати точки лову будь-якими засобами годівлі без порушення екології водойми. На території сектора, в охоронній зоні водоймища, дозволяється встановлення наметів, парасольок, у відведених місцях-мангалу.',
        'rules-item-0-text-6': 'Після закінчення риболовлі рибальське місце має бути прибране, мішки зі сміттям акуратно складені в урни, прокатне обладнання здано адміністрації та отримано заставний документ. САМОВИВОЗ Сміття ВІТАЄТЬСЯ!',
        'rules-item-0-text-7': 'Адміністрація водойми здійснює контроль за виконанням умов встановлених правил.',
        'rules-item-1-text-0': 'Використання будь-яких браконьєрських снастей (макушатники, смоктухи, бійки, доріжки, донки тощо), а також металевих садків.',
        'rules-item-1-text-1': 'Монтажі, які можуть покалічити рибу (супутники, макушатники, пружини тощо)',
        'rules-item-1-text-2': 'Шуміти, кричати, влаштовувати веселощі, включати голосно музику, стріляти тощо.',
        'rules-item-1-text-3': 'Перебувати на водоймі у стані алкогольного (наркотичного) сп\'яніння.',
        'rules-item-1-text-4': 'Заходити в сектор інших відвідувачів без їхньої згоди.',
        'rules-item-1-text-5': 'Використовувати металеві садки.',
        'rules-item-1-text-6': 'Купатися в зоні лову риби та плавати на плавзасобах.',
        'rules-item-1-text-7': 'Розводити багаття у невідведених для цього місцях.',
        'rules-item-1-text-8': 'Кидати недопалки та інше сміття у водойму на землю.',
        'rules-item-1-text-9': 'Заходити за охоронну зону водойми, луки та городи мешканців села.',
        'rules-item-2-text-0': 'Відмова від здачі сектора у належній чистоті тягне за собою штраф (200грн)',
        'rules-item-2-text-1': 'Намірне завдання пошкоджень або смерть риби тягне за собою штраф у розмірі 100грн/кг. Адміністрація має право видалити порушника правил водойми без відшкодування вартості невикористаного часу перебування на водоймі, а порушник вноситься до чорного списку.',
        'rules-item-2-text-2': 'Адміністрація залишає за собою право вносити зміни та доповнення до цього розділу',
    }
}

export default a
